.footer-section {
  background-color: #f5f5f5;
  padding: 100px;
  text-align: center;
  position: relative;
}

.footer-section p {
  font-size: 2.25rem;
  margin-right: 50px;
}

.footer-section button {
  position: absolute;
  top: 8rem;
  right: 30rem;
  margin-top: 2rem; 
  margin-right: 20px; 
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons {
  margin-bottom: 1rem;

}

.social-icons a {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: #333;
}

.contact-info {
  margin-top: 1rem;
}

.contact-info p {
  font-size: 1.5rem;
  margin: 0.5rem 0;
} 




.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.active {
  opacity: 1;
}


.resume-section {
  background-color: #1a1a21;
  color: black;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  position: relative;
  min-height: 100vh;
}

.resume-left-section {
  background-color: #252531; 
  color: white;
  width: 15%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resume-main-content {
  margin-left: 15%;
  width: 85%;
}

.resume-photo {
  margin-top: 5rem;
}

.resume-photo img {
  max-width: 100%;
  margin-top: 0rem;
  height: 8rem;
  border-radius: 50%;
  margin-bottom: 1rem;
  margin-left: 2.3rem;
}

.resume-skills {
  margin-top: 0rem;
}

.circular-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0rem;
}

.progress-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.resume-download {
  margin-top: auto;
  margin-bottom: 1.5rem;
}
.resume-right-div {
  background-color: #252531 !important;
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .resume-left-section {
    width: 100%;
    position: static; 
    margin-bottom: 1rem; 
  }

  .resume-main-content {
    margin-left: 0;
    width: 100%; 
  }
  
  .circular-field {
    margin-bottom: 0.5rem;
  }
  
  .resume-download {
    text-align: center; 
  }
}

my-info{
  display:flex; 
}

.circular-field {
  text-align: center;
}

.progress-circle {
  fill: transparent;
  stroke: rgba(255, 255, 4, 0.955); 
  stroke-width: 5; 
  stroke-dasharray: 251.2; 
  stroke-dashoffset: calc(251.2 - (251.2 * 70 / 100)); 
  transition: stroke-dashoffset 0.5s ease-in-out; 
}


.progress-percentage {
  font-size: 16px; 
  font-weight: bold;
  fill: white;
}
.reactjs-progress {
  stroke-dashoffset: calc(251.2 - (251.2 * 80 / 100));
}
.expressjs-progress {
  stroke-dashoffset: calc(251.2 - (251.2 * 75 / 100)); 
}

.nav-link {
  color: white;
  margin-right: 40px;
  font-size: 16px;
}

.nav-link:hover {
  text-decoration: underline;
  color: white;
}

.mt-15 {
  margin-top: 15rem !important;
}

@media (max-width: 13in) {
  .hide-on-small {
    display: none;
  }
}

.custom-margin-top {
  margin-top: 0.01rem; 
}


/* Styles for screens with a minimum width of 1201px and a maximum width of 1200px */
@media only screen and (min-width: 1201px) and (max-width: 1200px) {
  .resume-section {
    padding: 1rem; /* Adjust padding as needed */
  }

  .resume-left-section {
    width: 100%; /* Make the left section take full width */
    position: static; /* Remove absolute positioning */
    height: auto; /* Auto height */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .resume-photo {
    text-align: center;
  }

  .resume-skills {
    margin-top: 1rem; /* Adjust margin as needed */
  }

  .resume-download {
    margin-top: 1rem; /* Adjust margin as needed */
  }
}








@media (max-width: 768px) {
  .resume-left-section {
    width: 100%;
    position: static; 
    margin-bottom: 1rem; 
  }

  .resume-main-content {
    margin-left: 0;
    width: 100%; 
  }
  
  .circular-field {
    margin-bottom: 0.5rem;
  }
  
  .resume-download {
    text-align: center; 
  }
}

@media (max-width: 500px) {
  .footer-section {
    padding: 50px; /* Adjust padding for smaller screens */
  }

  .footer-section button {
    right: 10%; /* Adjust button position for smaller screens */
  }

  .footer-section p {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .social-icons a {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  .contact-info p {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }

  /* Add more adjustments for smaller screens in your styles */
}

@media (max-width: 500px) {
  /* Add additional media queries for screens with a maximum width of 500px if needed */
}



@media (max-width: 400px) {
  /* Additional styles for screens up to 400px width */
  .footer-section {
    padding: 30px; 
  }

  @media (max-width: 400px) {
    .img-fluid.me {
      display: none;
    }
    .btn.d-block.d-md-inline-block {
      display: block;
      margin: 0 auto; /* Center the button horizontally */
      text-align: center; /* Center the text inside the button */
    }
  }

  @media (max-width: 400px) {
    p.mb-5 {
      font-size: 16px; 
      text-align: left;
    }
    p.mb-0{
      font-size: 16px; 
      text-align: left;

    }
    p.pnumber{
      font-size: 16px; 
      text-align: left;
    }
  }
  
  
  

  .footer-section button {
    right: 5%; /* Adjust button position for even smaller screens */
  }

  /* Add more adjustments for screens up to 400px width */
}

@media (max-width: 320px) {
  /* Additional styles for screens up to 320px width (example) */
  .footer-section {
    padding: 20px; /* Adjust padding for very small screens */
  }

  .footer-section button {
    right: 2%; /* Adjust button position for very small screens */
  }

  /* Add more adjustments for screens up to 320px width */
}



